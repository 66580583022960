
.repo-card {
    height: 30em;
    
}
.repo-card .repo-header {
    overflow-y: auto;
}

.repo-card .repo-header .updated-at {
    color: green;
}

.repo-card .repo-description {
    overflow-y: auto;
    height: 45%;
    margin-bottom: 10%;
}

.repo-card .repo-description .repo-description {
    height: 100%;
}

.repo-card .repo-actions {
    height: 45%;
}

.projects-header {
    font-weight: 100;
    color: white;
    margin: 0 auto;
    margin-top: 5vh;
    margin-bottom: 2vh;
    display: inline-block;
    height: 10%;
}

.projects-header .title {
    float: left;
}
.projects-header .git-icon {
    float: right;
}

.projects-grid {
    width: 100%;
    height: 90%;
}

.projects-grid .ui.card {
    margin: 0 auto;
}

