.intro-contact {
  position: relative;
  width: 100%;
}

.intro-work {
    position: relative;
    width: 100%;
}

#home{
  height: 100%;
}
@keyframes bounce {
    50% {
      transform: translateY(-50%);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  