.resume-header {
    font-weight: 100;
    color: white;
    margin-top: 5vh;
    margin-bottom: 2vh;
    display: inline-block;
}

.resume-header .title {
    float: left;
}
.resume-header .git-icon {
    float: right;
}


.resume-preview canvas {
    margin: 0 auto;
    overflow: auto;
}

.resume-preview .react-pdf__Page {
    background-color: unset !important;
}

.resume .download-area-mobile {
    min-height: 200vh;
}

